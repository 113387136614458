import { useEffect, useMemo, useState } from 'react';
import { useTypedSelector } from 'store/reducers/use-typed-selector';
import { BrandliveEvent, Session, SessionPlaybackVideo, SessionTypesEnum } from 'types/working-model';
import { useIsTestBroadcast } from './session-hooks';
import { useLanguageParam } from 'components/live-event/utils';
import useTimestampStatus, { ETimestampStatus } from 'utils/use-timestamp-status';
import { StreamState } from 'types/live-state';
import { getStorageObject, setStorageObject } from 'utils/local-storage';

export const useCurrentDynamicVideo = (session: Session | null): [SessionPlaybackVideo | undefined, string | undefined] => {
	// redux
	const liveStates = useTypedSelector(state => state.LiveEventReducer.liveStates);
	const eventBundle = useTypedSelector(state => state.LiveEventReducer.eventBundle);
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const moderatorVideos = useTypedSelector(state => state.ModeratorReducer.moderatorVideos);
	const sessionUuid = session?.uuid;
	const sessionEndTime = session?.end_timestamp;

	// state hook
	const [hasBeenLive, setHasBeenLive] = useState(getStorageObject(`rcvd-live.${sessionUuid}`)?.started || false);

	// custom hooks
	const isTestBroadcast = useIsTestBroadcast(session);
	const language = useLanguageParam();
	const timestampStatus = useTimestampStatus(session);

	// setup variables
	const event = eventBundle || workingEvent;
	const prelive = timestampStatus === ETimestampStatus.preLive;
	const scheduledLive = timestampStatus === ETimestampStatus.live;
	const singleStream = session?.streaming_options.single_stream;
	const defaultLanguage = session?.default_language;
	const isOnDemand = session?.session_type === SessionTypesEnum.onDemand;
	const sessionVideos = event?.sessionVideos ?? moderatorVideos;
	const replayOn = session?.replay_on;

	// session streams for this session and language (or all)
	const sessionStreams = useMemo(() => {
		return sessionVideos?.filter(stream => {
			// if the stream is from this session
			if (stream.session_uuid === session?.uuid) {
				// if singleStream is on, return videos that match the default language
				// OR are videos set to "all"
				if (singleStream) {
					return stream.language === defaultLanguage || stream.language === 'all';
				} else {
					// otherwise return videos matching the language or set to "all"
					return stream.language === language || stream.language === 'all';
				}
			}
		});
	}, [sessionVideos, session?.uuid, language, singleStream, defaultLanguage]);

	// playback url for the live stream
	const livePlayback = useMemo(() => {
		return sessionStreams?.find(stream => stream.type === 'live_stream');
	}, [sessionStreams]);

	// playback url for the replay stream
	const replayPlayback = useMemo(() => {
		return sessionStreams?.find(stream => stream.type === 'replay');
	}, [sessionStreams]);

	// playback url for the user-set placeholder video
	const placeholderPlayback = useMemo(() => {
		return sessionStreams?.find(stream => stream.type === 'placeholder_video');
	}, [sessionStreams]);

	// live states are currently updated via the useLive hook at the session level
	// to prevent having multiple hooks fetching this since it's hit so frequently,
	// we will just grab the live state from the store here and assume that this
	// is being updated elsewhere. 
	const liveState = livePlayback?.playback_url ?
		(liveStates[livePlayback.playback_url]?.state ?? StreamState.offline) :
		StreamState.offline;

	const isLive = liveState === StreamState.live;

	useEffect(() => {
		if (!isTestBroadcast && isLive && sessionEndTime) {
			setHasBeenLive(true);
			const hoursLeft = (sessionEndTime - Date.now()) / 1000 / 60 / 60;
			setStorageObject(`rcvd-live.${sessionUuid}`, { started: true }, hoursLeft); // expire at end of session
		}
	}, [isLive, sessionUuid, sessionEndTime, isTestBroadcast]);

	// this is an on-demand session - return the on-demand video ONLY
	if (isOnDemand) {
		return [undefined, undefined];
	}

	// if the event is live and not a test broadcast, return the live playback url
	if (isLive && !isTestBroadcast) {
		return [livePlayback, livePlayback?.playback_url];
	}

	// this is prelive and there is a placeholder video, so show the placeholder video
	if (prelive && placeholderPlayback?.playback_url) {
		return [placeholderPlayback, placeholderPlayback?.playback_url];
	}

	// this is not prelive and there is a replay video and replay is on, show the replay
	if (!prelive && replayOn && replayPlayback?.playback_url) {
		return [replayPlayback, replayPlayback.playback_url];
	}

	// this is the scheduled live time and the video has never been live for this viewer
	// so show the placeholder video if it exists, otherwise will fall back to the static image
	if (scheduledLive && !hasBeenLive) {
		return [placeholderPlayback, placeholderPlayback?.playback_url];
	}

	return [undefined, undefined];
};
